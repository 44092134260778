/* eslint-disable arrow-parens */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import { ApolloClient } from 'apollo-client';
import { from, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
import { UNAUTHENTICATED_ERROR } from '../../constants';
import { refreshToken, logout } from '../../authHandle';
import createCache from './createCache';

let isFetchingToken = false;
let tokenSubscribers = [];

function subscribeTokenRefresh(cb) {
  tokenSubscribers.push(cb);
}
function onTokenRefreshed(err) {
  tokenSubscribers.map(cb => cb(err));
}

const link = from([
  onError(({
    graphQLErrors,
    networkError,
    operation,
    forward,
  // eslint-disable-next-line consistent-return
  }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, locations, path } = err;
        switch (message) {
          case UNAUTHENTICATED_ERROR: {
            // Queue up all unauthorized queries and attempt to refresh the token
            return new Observable(async observer => {
              try {
                const retryRequest = () => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };

                  return forward(operation).subscribe(subscriber);
                };
                if (!isFetchingToken) {
                  isFetchingToken = true;
                  try {
                    const response = await refreshToken();
                    if (response.status !== 200) throw new Error('Unable to refresh access token');
                    isFetchingToken = false;
                    onTokenRefreshed(null);
                    tokenSubscribers = [];
                    return retryRequest();
                  } catch (err) {
                    onTokenRefreshed(err);

                    tokenSubscribers = [];
                    isFetchingToken = false;

                    return logout(UNAUTHENTICATED_ERROR);
                  }
                }

                const tokenSubscriber = new Promise(resolve => {
                  subscribeTokenRefresh(errRefreshing => {
                    if (!errRefreshing) return resolve(retryRequest());
                    return true;
                  });
                });

                return tokenSubscriber;
              } catch (e) {
                observer.error(e);
              }
              return true;
            });
          } default: {
            // eslint-disable-next-line no-console
            console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          }
        }
      }
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`); // eslint-disable-line no-console
    }
  }),
  // eslint-disable-next-line no-undef
  ...(__DEV__ ? [apolloLogger] : []),
  new HttpLink({
    uri: '/graphql',
    credentials: 'include',
  }),
]);

const cache = createCache();

export default function createApolloClient() {
  return new ApolloClient({
    link,
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
