const refreshURL = '/token/refresh';
const logoutURL = '/logout';

export const refreshToken = () => {
  const configuration = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
  };
  return fetch(refreshURL, configuration);
};

export const refreshTokenServer = () => {
  const configuration = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
  };
  return fetch(refreshURL, configuration);
};

export const logout = (type) => {
  window.location = `${logoutURL}?type=${type}`;
};

