export const EN_US = 'en-us';
export const EN_GB = 'en-gb';
export const FR_CA = 'fr-ca';

export const DEFAULT_LOCALE = EN_US;
export const LOCALES = [
  EN_US,
  EN_GB,
  FR_CA,
];

const localeHelper = {
  storageMartLocale: (currentLocale) => {
    if (!currentLocale) return 'en';
    const locale = currentLocale.replace(/-/g, '').toLowerCase();
    return locale !== 'enus' ? locale : 'en';
  },
  getTranslation: (translations, currentLocale) => {
    const translation = translations.find(element => element.locale === currentLocale);
    return translation.text;
  },
  getPhoneNumber: (message, currentLocale) => {
    const phoneNumber = currentLocale === EN_GB ?
      `44 ${message}` :
      message;
    return phoneNumber;
  },
};

export default localeHelper;
