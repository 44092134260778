import {
  LOADING_COMPONENT,
  DONE_LOADING,
} from '../constants';

export default function loading(state = { components: [] }, action) {
  switch (action.type) {
    case LOADING_COMPONENT: {
      const tempArr = state.components;
      const index = tempArr.findIndex(obj => obj.component === action.component);
      if (index === -1) {
        tempArr.push({
          component: action.component,
          loading: true,
        });
      } else {
        tempArr[index].loading = true;
      }
      return {
        ...state,
        components: tempArr,
      };
    }
    case DONE_LOADING: {
      const componentArr = state.components;
      const index = componentArr.findIndex(obj => obj.component === action.component);
      if (index === -1) {
        componentArr.push({
          component: action.component,
          loading: false,
        });
      } else {
        componentArr[index].loading = false;
      }
      return {
        ...state,
        components: componentArr,
      };
    }
    default: {
      return state;
    }
  }
}
