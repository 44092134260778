import {
  ALERT,
  CLOSE_ALERT,
} from '../constants';

export default function alert(state = { alerts: [] }, action) {
  if (!state.alerts) {
    state.alerts = [];
  }
  switch (action.type) {
    case ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: Date.now(),
            text: action.text,
            color: action.color,
            timeout: action.timeout,
          },
        ],
      };
    case CLOSE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter(stateAlert => stateAlert.id !== action.id),
      };
    default: {
      return state;
    }
  }
}
