/* eslint-disable id-length */
/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
export const AUTH_CHECK_FAIL = 'AUTH_CHECK_FAIL';
export const ALERT = 'ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const SET_CURRENT_TENANT = 'SET_CURRENT_TENANT';
export const SET_CURRENT_TENANT_LEDGERS = 'SET_CURRENT_TENANT_LEDGERS';
export const REFETCHING_CURRENT_TENANT = 'REFETCHING_CURRENT_TENANT';
export const REFETCH_CURRENT_TENANT = 'REFETCH_CURRENT_TENANT';
export const REFETCH_CURRENT_TENANT_ERROR = 'REFETCH_CURRENT_TENANT_ERROR';
export const REFETCH_CURRENT_TENANT_LEDGER = 'REFETCH_CURRENT_TENANT_LEDGER';
export const REFETCH_CURRENT_TENANT_LEDGER_ERROR = 'REFETCH_CURRENT_TENANT_LEDGER_ERROR';
export const REFETCH_CURRENT_TENANT_LEDGER_SUCCESS = 'REFETCH_CURRENT_TENANT_LEDGER_SUCCESS';
export const SET_CURRENT_FACILITY = 'SET_CURRENT_FACILITY';
export const SET_CURRENT_ACCOUNTS = 'SET_CURRENT_ACCOUNTS';
export const DEFAULT_PROVIDER = 'storedge';
export const TOTALRECALL_PROVIDER = 'totalrecall';
export const CONTACT_TYPE = 'contact';
export const AUTH_USER_TYPE = 'authorized-user';
export const SET_IDLE = 'SET_IDLE';
export const SET_ACTIVE = 'SET_ACTIVE';
export const LOADING_COMPONENT = 'LOADING_COMPONENT';
export const DONE_LOADING = 'DONE_LOADING';
export const US_COUNTRY_CODE = 'us';
export const GB_COUNTRY_CODE = 'gb';
export const CA_COUNTRY_CODE = 'ca';
export const SIGN_OUT = 'SIGN_OUT';
export const UNAUTHENTICATED_ERROR = 'UNAUTHORIZED';
export const MMS_BRAND_NAME = 'Manhattan Mini Storage';
export const STOREDGE_SOFTWARE = 'storedge';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const AMERICAN_EXPRESS_IDENTIFIER = 'ame';
