import {
  SET_IDLE,
  SET_ACTIVE,
} from '../constants';

export default function activity(state = {}, action) {
  switch (action.type) {
    case SET_IDLE:
      return {
        ...state,
        active: false,
      };
    case SET_ACTIVE:
      return {
        ...state,
        active: true,
      };
    default: {
      return state;
    }
  }
}
