import {
  SET_CURRENT_USER,
} from '../constants';

export default function user(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload.toObject(),
      };
    default:
      return state;
  }
}
