import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { CookiesProvider } from 'react-cookie';
import { DEFAULT_LOCALE } from '../helpers/localeHelper';


const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  ...ReduxProvider.childContextTypes,
  // Apollo Client
  client: PropTypes.object.isRequired,
  // ReactIntl
  intl: IntlProvider.childContextTypes.intl,
  locale: PropTypes.string,
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
class App extends React.PureComponent {
    static propTypes = {
      context: PropTypes.shape(ContextType).isRequired,
      children: PropTypes.element.isRequired,
    };

    static childContextTypes = ContextType;

    constructor(props) {
      super(props);
      this.state = {
        appLocale: this.props.context.intl.locale || DEFAULT_LOCALE,
      };
    }
    getChildContext() {
      return this.props.context;
    }

    // NOTE: This methods are not needed if you update URL by setLocale action.
    //
    componentWillMount() {
      const store = this.props.context && this.props.context.store;
      if (store) {
        this.lastLocale = store.getState().intl.locale;
        this.unsubscribe = store.subscribe(() => {
          const state = store.getState();
          const { newLocale, locale } = state.intl;
          let language = store.getState().intl.newLocale;

          if (language == null) {
            language = store.getState().intl.locale;
          }
          if (!newLocale && this.state.appLocale !== locale) {
            this.setState({
              appLocale: locale,
            });
          }
        });
      }
    }
    //
    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    }

    render() {
      const { client } = this.props.context;
      const { appLocale } = this.state;
      // NOTE: If you need to add or modify header, footer etc. of the app,
      // please do that inside the Layout component.
      return (
        <CookiesProvider>
          <ApolloProvider client={client}>
            <IntlProvider
              key={appLocale}
              locale={appLocale}
            >
              {this.props.children}
            </IntlProvider>
          </ApolloProvider>
        </CookiesProvider>
      );
    }
}

export default App;
