import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './ErrorPage.scss';

const ErrorPage = function (props) {
  if (__DEV__ && props.error) { // eslint-disable-line no-undef
    return (
      <div>
        <h1>{props.error.name}</h1>
        <pre>{props.error.stack}</pre>
      </div>
    );
  }

  return (
    <div>
      <h1>Error</h1>
      <p>Sorry, a critical error occurred on this page.</p>
    </div>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    stack: PropTypes.string.isRequired,
  }),
};

ErrorPage.defaultProps = {
  error: null,
};

export { ErrorPage as ErrorPageWithoutStyle };
export default withStyles(styles)(ErrorPage);
