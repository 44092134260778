import {
  SET_CURRENT_TENANT,
  SET_CURRENT_TENANT_LEDGERS,
  REFETCHING_CURRENT_TENANT,
  REFETCH_CURRENT_TENANT_LEDGER,
  REFETCH_CURRENT_TENANT_LEDGER_ERROR,
  REFETCH_CURRENT_TENANT,
  REFETCH_CURRENT_TENANT_ERROR,
  SET_CURRENT_FACILITY,
  SET_CURRENT_ACCOUNTS,
} from '../constants';

export default function tenants(state = {}, action) {
  switch (action.type) {
    case SET_CURRENT_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };
    case SET_CURRENT_TENANT_LEDGERS:
      return {
        ...state,
        ledgers: action.payload,
      };
    case SET_CURRENT_FACILITY:
      return {
        ...state,
        facility: action.payload,
      };
    case REFETCHING_CURRENT_TENANT:
      return {
        ...state,
        tenant: {
          loading: true,
        },
      }
    case REFETCH_CURRENT_TENANT_LEDGER:
      return {
        ...state,
        ledgers: action.payload,
      };
    case REFETCH_CURRENT_TENANT_LEDGER_ERROR:
      return {
        ...state,
      };
    case REFETCH_CURRENT_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };
    case REFETCH_CURRENT_TENANT_ERROR:
      return {
        ...state,
      };
    case SET_CURRENT_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    default: {
      return state;
    }
  }
}
