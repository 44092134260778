import { combineReducers } from 'redux';
import activity from './activity';
import alerts from './alerts';
import intl from './intl';
import loading from './loading';
import runtime from './runtime';
import tenants from './tenants';
import user from './user';

export default combineReducers({
  activity,
  alerts,
  intl,
  loading,
  runtime,
  tenants,
  user,
});
